import React, { useEffect, Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useSubscription, gql } from "@apollo/client";
import {Animated} from "react-animated-css";

const useUnload = fn => {
    const cb = React.useRef(fn);
  
    React.useEffect(() => {
      const onUnload = cb.current;
      window.addEventListener('beforeunload', onUnload);
      return () => {
        window.removeEventListener('beforeunload', onUnload);
      };
    }, [cb]);
  };

const SUBMIT = gql`
mutation updateParty($id:Int!, $email: String!, $name_anzahl: String!, $zeitraum: String!) {
    update_agnes_party(where: {id: {_eq: $id}}, _set: {email: $email, name_anzahl: $name_anzahl, zeitraum: $zeitraum,blocked:false}){returning {
        id
      }
    }
  }
`


const NOTIFY_PARTY = gql`
subscription getParty {
    agnes_party(order_by: {hotel_id: asc, anbau: asc, zimmer_nummer: asc}) {
      id
      hotel_id
      zimmer_nummer
      ausstattung
      zusatz
      name_anzahl
      zeitraum
      email
      blocked
      anbau
    }
  }`;



const Buchung = () => {

    useUnload(e => {
        e.preventDefault();
        const exit = alert('Are you sure you want to leave?');
        //if (exit) window.close();
      });
      //return <div>Try closing the window.</div>;

    const [zimmer, setZimmer] = useState(0);
    const [zimmerNr, setZimmerNr] = useState(0);
    const [hotel, setHotel] = useState(0);
    const [nameAnzahl, setNameAnzahl] = useState("");
    const [zeitraum, setZeitraum] = useState(0);
    const [email, setEmail] = useState("");
    const [ausstattung, setAusstattung] = useState("");
    const [zusatz, setZusatz] = useState("");
    const [submitAnmeldung] = useMutation(SUBMIT);

    const updateZimmer = id => {
        setZimmer(id);
    }
    const updateZimmerNr = id => {
        setZimmerNr(id);
    }
    const updateHotel = id => {
        setHotel(id);
    }

    const updateNameAnzahl = txt => {
        setNameAnzahl(txt);
    }

    const updateZeitraum = zeit => {
        setZeitraum(zeit);
    }

    const updateEmail = email => {
        setEmail(email);
    }

    const updateAusstattung = ausstattung => {
        setAusstattung(ausstattung);
    }

    const updateZusatz = zusatz => {
        setZusatz(zusatz);
    }

    const { register, setValue, errors, handleSubmit, formState,reset  } = useForm();
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(
        false
      );

      const [isErrorfullySubmitted, setIsErrorfullySubmitted] = useState(
        false
      );  
     

      const onSubmit = async data => {
       
        let res;
        try {
         
            res = await submitAnmeldung({ variables: { id: zimmer, email: data.email, name_anzahl: data.name_anzahl,zeitraum: data.zeitraum } });
            //console.log(res)
            
        } 
        catch(e) {
            console.log(e)
            setIsErrorfullySubmitted(true); 

        }

        if(res && res.data.update_agnes_party.returning[0].id){
            setIsSuccessfullySubmitted(true);
            updateZimmer(0);
            updateEmail("")
            updateNameAnzahl("")
            updateZeitraum(0)
            reset({

               name_anzahl: "",
               email: "",
             }, {
               errors: false, // errors will not be reset 
               isSubmitted: false,
               touched: false,
               isValid: false,
               submitCount: false,
             });
        }
        else {      
                setIsErrorfullySubmitted(true); 
        }
         
    };


   
    const { loading, error, data } = useSubscription(NOTIFY_PARTY);
    //  console.log(loading);
    //  console.log(error);
    //  console.log(data);
     let hotelc = 1;
     let change = 0;
     if (loading) return <p>Loading ...</p>;
    else return (
        <div className="content">
             <Animated animationIn="slideInDown" animationOut="slideOutUp" isVisible={zimmer !== 0 ? true : false}>
            <form id="signup" onSubmit={handleSubmit(onSubmit)} className={zimmer !== 0 ? "showForm" : "hideForm"}>
            <h2>Anmeldung</h2>
            <div ><span className="form-label">Hotel:</span> {hotel === 1 ? "Seehotel Sonnengruß" : "Hotel Diemelkroon"}</div>
            <div><span className="form-label">Ausgewähltes Zimmer:</span> {zimmerNr} ({ausstattung} {zusatz})</div>

     
            <br/>
            <span className="form-label">In welchem Zeitraum?</span><br/>
            <div className="check"><input type="radio" name="zeitraum" id="zeitraum1" value="1" {...register('zeitraum', { required: true })} checked={zeitraum === "1" ? "checked" : null}  onClick={() => updateZeitraum(1)}/>
            <label for="zeitraum">29.-30.7.22</label></div>
            
            <div className="check"><input type="radio" name="zeitraum" id="zeitraum2" value="2" {...register('zeitraum', { required: true })} checked={zeitraum === "2" ? "checked" : null}  onClick={() => updateZeitraum(2)}/>
            <label for="zeitraum">30.-31.07.22</label></div>
            
            <div className="check"><input type="radio" name="zeitraum" id="zeitraum3" value="3" {...register('zeitraum', { required: true })} checked={zeitraum === "3" ? "checked" : null} onClick={() => updateZeitraum(3)}/>
            <label for="zeitraum">29.-31.07.22</label></div><br/>
            <label className="fl" for="name_anzahl"><span className="form-label">Dein Name und die Personenanzahl</span></label>
            <input type="text" name="name_anzahl" {...register('name_anzahl', { required: true })}   /><br/>

            <label for="email"><span className="form-label">Deine E-Mailadresse</span></label>
            <input type="email" name="email" {...register('email', { required: true })} className="form-email" /><br/>
            <input type="submit"  className="primary-btn" value="anmelden"  />                  
            </form>
            </Animated>
            {/* {isSuccessfullySubmitted ? <div>ok</div> : <div>no</div>} */}
         
                <Animated animationIn="bounceInDown" animationOut="bounceOutUp" isVisible={isSuccessfullySubmitted}>
          <div className="success" className={isSuccessfullySubmitted ? "success showForm" : "success hideForm"}>
               <div className="content-banner s1 no-space top-space">

<div className="layout success-mail">
    <p>Danke die Eintragung war erfolgreich.</p>
          <div className="flex"> 
          <input type="button" className="secondary-btn" value="&#xd7; schließen" onClick={() => setIsSuccessfullySubmitted(false)} />
                            </div>
    </div></div>      
          </div>
          </Animated>
     
                {isErrorfullySubmitted && (
          <div className="error">
               <div className="content-banner s1 no-space top-space">

            <div className="layout error-mail">
    <p>Leider ist ein Fehler aufgetreten. Bitte per E-Mail an ... wenden.</p>
          <div className="flex"> 
          <input type="button" className="secondary-btn" value="&#xd7; schließen" onClick={() => setIsErrorfullySubmitted(false)} />
                            </div>
    </div></div>      
          </div>
        )}
            <h2>Zimmer Seehotel Sonnengruß</h2>
            <div>
                <div className="zimmer"> 
                <div className="status-spacer"></div>
                <div className="room-no">Zimmernr.</div>
                <div className="room-ausstattung">Ausstattung</div>
                {/* <div className="room-name">Name und Anzahl Personen</div> */}
                <div className="zeitraum">
                    <div>Zeitraum</div>
                    <div className="zeitraum-datum">
                        <div className="zeitraum-single">29.-30.7.22</div>
                        <div className="zeitraum-single">30.-31.07.22</div>
                        <div className="zeitraum-single">29.-31.07.22</div>
                    </div>
                </div>
                {/* <div className="room-email">E-Mail</div> */}
                <div className="actions"></div>
                </div>

                 {data.agnes_party.map((eintrag, key) => {
                    

                    return(
                        <>
                        
                        {eintrag.hotel_id > hotelc ? <h2 className="diemelkroon">Zimmer Hotel Diemelkroon</h2> : null}
                        
                        

                        <div className={eintrag.id === zimmer ? "zimmer selected" : "zimmer"}  key={key} >
                            
                            
                            <div className={
                                eintrag.blocked ? "status blocked" : !eintrag.blocked && eintrag.email ? "status full" : "status free"}></div>
                            <div className={eintrag.email ? "room-no room-blocked" : "room-no" }>{eintrag.zimmer_nummer} {eintrag.anbau ? "- Anbau" : null}</div>
                            <div className={eintrag.email ? "room-ausstattung room-blocked" : "room-ausstattung" }>{eintrag.ausstattung} {eintrag.zusatz}</div>
                           
                            {/* <div className={eintrag.email ? "room-name room-blocked" : "room-name" }>{eintrag.name_anzahl ? eintrag.name_anzahl.substring(0,3)+"..." : null} </div> */}


                            <div className={eintrag.email ? "zeitraum room-blocked" : "zeitraum" }>
                               
                                <div className="zeitraum-datum">
                                    <div className="zeitraum-single">{eintrag.zeitraum === "1" ? "●" : "–"}</div>
                                    <div className="zeitraum-single">{eintrag.zeitraum === "2" ? "●" : "–"}</div>
                                    <div className="zeitraum-single">{eintrag.zeitraum === "3" ? "●" : "–"}</div>
                                </div>
                            </div>

                           
                            {/* <div className={eintrag.email ? "room-email room-blocked" : "room-email" }>{eintrag.email ? eintrag.email.substring(0,3)+"..." : null} </div> */}
                            
                            <div  className={eintrag.email ? "actions room-blocked" : "actions" }>
                            {eintrag.email ? <button 
                             className={eintrag.email ? "room-blocked" : null }
                            disabled={eintrag.blocked ? true : false}
                            onClick={() => {updateZimmer(eintrag.id); updateNameAnzahl(eintrag.name_anzahl);
                                setValue("name_anzahl", eintrag.name_anzahl)
                            updateEmail(eintrag.email);
                            setValue("email", eintrag.email)
                            setValue("zeitraum", eintrag.zeitraum)

                            updateZeitraum(eintrag.zeitraum);
                            updateZimmerNr(eintrag.zimmer_nummer);
                            updateHotel(eintrag.hotel_id); 
                            updateAusstattung(eintrag.ausstattung);
                            updateZusatz(eintrag.zusatz);    
                        }}>Bearbeiten</button> : <button 
                        disabled={eintrag.blocked ? true : false}
                        onClick={() => {updateZimmer(eintrag.id);
                            setValue("name_anzahl", "")
                            updateNameAnzahl("");
                            updateEmail(""); 
                            setValue("email", "");
                            setValue("zeitraum", "");
                            updateZeitraum(0);
                            updateAusstattung(eintrag.ausstattung);
                            updateZusatz(eintrag.zusatz); 
                            updateZimmerNr(eintrag.zimmer_nummer);
                            updateHotel(0);
                            }}>Eintragen</button>}
                                </div>
                        </div>
                        </>
                    )
                    // if(eintrag.hotel_id === hotelc) hotelc= hotelc+1;
                })} 
            
       
            </div>
            <br/><br/>
            <p>DB = Doppelbett | EB = Einzel- oder Etagenbett | BB = Bankbetten<br/> Bitte Handtücher mitbringen und als Einzelperson bitte kein Doppelzimmer buchen. Danke!</p>
        </div>
       
    )
}

export default Buchung