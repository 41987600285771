import {  HashRouter as Router, Routes, Route } from 'react-router-dom'


import './App.css';
import Buchung from "./Buchung"
import Liste from "./Liste"


const App = () => (
<Router>
  <Routes>

    <Route path="/" element={<Buchung/>} />
    <Route exact path="/liste" element={<Liste/>} />
  </Routes>
</Router>
)

export default App;