// Remove the apollo-boost import and change to this:
import ApolloClient from "apollo-client";
// Setup the network "links"
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';


const httpsLink = new HttpLink({
  uri: "https://www.digitalpublishing.xyz/v1/graphql",
  headers: {
    'x-hasura-admin-secret': 'dpop20'
  }
});

const wssLink = new WebSocketLink({
  uri: "wss://www.digitalpublishing.xyz/v1/graphql",
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        'x-hasura-admin-secret': 'dpop20'
      }
    }
  }
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wssLink,
  httpsLink
);

const createApolloClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link
  });
};

const Connection = createApolloClient();

export default Connection


/*
export default new GraphQLWsLink(createClient({
  uri: "wss://www.digitalpublishing.xyz/v1/graphql",
        connectionParams:  {
            'x-hasura-admin-secret': 'dpop20'
        }
}));

export default new GraphQLWsLink(createClient({
  uri: "https://www.digitalpublishing.xyz/v1/graphql",
        connectionParams:  {
            'x-hasura-admin-secret': 'dpop20'
        }
}));
*/
/*
export default new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        // uri: "http://159.89.9.48/v1/graphql",
        uri: "https://www.digitalpublishing.xyz/v1/graphql",
        headers: {
            'x-hasura-admin-secret': 'dpop20'
        }
    }),
})

*/